body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('./assets/bbld/Main_Background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.main-container {
  padding: 2rem;
  margin: 5rem auto 2rem;
  max-width: 900px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  max-width: 25vh;

}

.button {
  display: inline-block;
  margin: 1rem;
  padding: 0.75rem 0;
  width: 200px;
  background-color: #F98282;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-transform: uppercase;
  text-align: center;
}

.button:hover {
  background-color: #333;
  transform: translateY(-2px);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

/* Team Styles */
.pretty-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allows items to wrap to the next line on smaller screens */
  margin-top: 2rem;
}

.pretty-card {
  flex: 1 1 100%; /* Flex-basis 100% to make items stack on smaller screens */
  max-width: 45%;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pretty-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bbld-image {
  max-width: 25vh;
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.team-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

h3 {
  margin-top: 10px;
  font-size: 1.5em;
  color: #007bff;
}

p {
  font-size: 1em;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
}

.role {
  font-size: 1.2em;
  margin-bottom: 1rem;
  color: #555;
}

.description {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
  text-align: left;
}

/* Roadmap Styles */
.roadmap-container {
  margin-top: 30px;
}

.roadmap-phase {
  margin-bottom: 30px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.roadmap-phase h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
  text-align: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
}

.roadmap-phase ul {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.roadmap-phase ul li {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #555;
  padding-left: 15px;
  position: relative;
}

.roadmap-phase ul li::before {
  content: "•";
  color: #007bff;
  position: absolute;
  left: 0;
}
.roadmap-phase:hover{
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);


}

.input-slider {
  width: 85%;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main-container {
    margin: 2rem 1rem; /* Reduce margins on smaller screens */
    padding: 1rem; /* Reduce padding */
  }

  .pretty-card {
    max-width: 100%; /* Make team members full width on mobile */
    margin: 1rem 0; /* Adjust margins for better spacing */
  }

  h3 {
    font-size: 1.3em; /* Reduce font size for smaller screens */
  }

  .button {
    width: 100%; /* Make buttons full width on mobile */
    margin: 0.5rem 0; /* Adjust margin for better spacing */
  }

  .navbar {
    flex-direction: column;
    padding: 1rem;
  }

  .roadmap-phase {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .roadmap-phase h3 {
    font-size: 1.3em; /* Reduce heading size for better readability on small screens */
  }

  .roadmap-phase ul li {
    font-size: 1em; /* Reduce list item font size for better readability */
  }
}

@media (max-width: 480px) {
  .team-image {
    width: 100px; /* Reduce image size for very small screens */
    height: 100px;
  }

  .pretty-card {
    padding: 15px; /* Adjust padding */
  }

  .button {
    font-size: 0.9rem; /* Slightly reduce font size on buttons */
  }

  h3 {
    font-size: 1.2em; /* Further reduce heading size */
  }

  p {
    font-size: 0.9em; /* Reduce paragraph font size */
  }
}

.number-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-number {
  width: 100px; /* Adjust the width as needed */
  padding: 5px;
  text-align: center;
  font-size: 16px;
  margin: 0 10px;
}

.arrow-button {
  background-color: #F98282;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  border-radius: 4px;
}

.arrow-button:hover {
  background-color: #333;
  transform: translateY(-2px);
  }

  .error-message,
.success-message {
  text-align: center;
  font-weight: bold;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}