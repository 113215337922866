.uniswap-full-width-card {
    display: flex;
    justify-content: center;
    padding: 20px;
    width: 100vw; /* Full viewport width */
    margin: 0 auto;
}

.uniswap-pretty-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    width: 90vw; /* Adjusted to 90% of the viewport width */
    max-width: 1200px; /* Optional: set a maximum width */
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
}

.uniswap-coin-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Space below the coin image */
    width: 100%;
}

.uniswap-coin-container img {
    max-width: 30vh; /* Adjusted size */
    height: auto;
    object-fit: contain;
}

.uniswap-header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px; /* Space below the header */
    width: 100%;
}

.uniswap-header-logo {
    max-width: 30px; /* Adjust size as needed */
    margin: 0 10px; /* Space between logo and text */
}

.uniswap-iframe-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.uniswap-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}

.uniswap-button {
    display: inline-block;
    margin: 1rem;
    padding: 0.75rem 0;
    width: 200px;
    background-color: #F98282;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-transform: uppercase;
    text-align: center;
}

.uniswap-button:hover {
    background-color: #333;
    transform: translateY(-2px);
}
