.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 0.9rem;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.x-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.x-logo:hover {
  transform: scale(1.1);
}
